import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/ui/nav/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/ui/Provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/ui/font.ts\",\"import\":\"Lilita_One\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\"]}],\"variableName\":\"lilita\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/ui/font.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./../../public/fonts/supercell-magic.ttf\",\"display\":\"swap\"}],\"variableName\":\"supercell\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
