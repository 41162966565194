'use client';

import Image from 'next/image';
import Icon from '@/app/ui/icons/Icon';
import Button from '@/app/ui/nav/Button';
import { Dict } from '@/app/types/Dict';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { lilita } from '@/app/ui/font';
import { useNavigation } from '@/app/contexts/Navigation';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import logo from './images/logo.webp';

const navigation = [
  {
    name: '$goin',
    refname: 'coin',
    activeRef: 'coin',
  },
  {
    name: 'info',
    refname: 'nextgen',
    activeRef: 'nextgen',
  },
  {
    name: 'game',
    refname: 'findway',
    activeRef: 'findway',
  },
  {
    name: 'roadmap',
    refname: 'roadmapSection',
    activeRef: 'roadmap',
  },
  {
    name: 'tokenomics',
    refname: 'tokenomics',
    activeRef: 'tokenomics',
  },
];

type AvailableLinks =
  | 'hero'
  | 'coin'
  | 'nextgen'
  | 'findway'
  | 'roadmap'
  | 'tokenomics';

export default function Navbar() {
  const { refs } = useNavigation();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState<AvailableLinks>('hero');
  const pathname = usePathname();

  useEffect(() => {
    const sections: AvailableLinks[] = [
      'hero',
      'coin',
      'nextgen',
      'findway',
      'roadmap',
      'tokenomics',
    ];
    const createObserver = (section: AvailableLinks) =>
      new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setActive(section);
          }
        },
        { threshold: 0.8 },
      );
    const observers = sections.reduce(
      (acc, section) => ({ ...acc, [section]: createObserver(section) }),
      {},
    );
    const handleRefs = (
      refAction: (observer: IntersectionObserver, ref: HTMLDivElement) => void,
    ) =>
      sections.forEach((section) => {
        if (refs?.[section])
          refAction(
            observers[section as keyof typeof observers],
            refs[section],
          );
      });
    // Start observing
    handleRefs((observer, ref) => observer.observe(ref));
    // Clean-up function
    return () => {
      // Stop observing
      handleRefs((observer, ref) => observer.unobserve(ref));
    };
  }, [refs]);
  return (
    <nav className="fixed left-0 right-0 top-0 z-50 mx-auto mt-[10px] w-[80%] rounded-[24px] bg-[rgba(9,10,30,0.86)] p-[10px] backdrop-blur-[2.9px] lg:mt-0 lg:w-full lg:rounded-none lg:bg-[rgba(17,7,35,0.47)] lg:px-11 lg:py-[27px]">
      <div className="flex w-full justify-between px-4 py-5 lg:container lg:px-0 lg:py-0">
        <div className="flex items-center">
          {pathname === '/' ? (
            <button
              type="button"
              onClick={() => {
                if (refs && refs.hero) {
                  refs.hero.scrollIntoView({
                    behavior: 'smooth',
                  });
                }
              }}
            >
              <Image
                alt="logo"
                src={logo}
                width={162}
                height={36}
                className="hidden lg:block"
              />
              <div className="block lg:hidden">
                <Icon name="smallLogo" width={52} height={33} />
              </div>
            </button>
          ) : (
            <Link href="/">
              <Image
                alt="logo"
                src={logo}
                width={162}
                height={36}
                className="hidden lg:block"
              />
              <div className="block lg:hidden">
                <Icon name="smallLogo" width={52} height={33} />
              </div>
            </Link>
          )}
        </div>
        <div className="hidden gap-[35px] text-lg uppercase lg:flex lg:items-center">
          {pathname === '/' &&
            navigation.map((item: Dict) => (
              <Button
                onClick={() => {
                  if (refs) {
                    const ref: HTMLDivElement | null | undefined =
                      refs?.[item.refname as keyof typeof refs];
                    if (ref) {
                      ref.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }
                  }
                }}
                type="link"
                key={item.name}
                href="/"
                className={clsx('', {
                  'text-vivid-orange hover:text-vivid-orange':
                    active === item.activeRef,
                  'text-pale-blue-gray hover:text-ice-blue':
                    active !== item.activeRef,
                })}
              >
                {item.name}
              </Button>
            ))}
          {pathname === '/' && (
            <a
              target="_blank"
              href="/pdf/gamster-whitepaper.pdf"
              className="text-pale-blue-gray hover:text-vivid-orange"
            >
              Whitepaper
            </a>
          )}
        </div>
        {pathname === '/' && (
          <div className="flex text-pale-mist lg:hidden">
            <button
              type="button"
              onClick={() => setOpen((prev) => !prev)}
              aria-label="Menu"
            >
              <Icon name="burger" width={30} height={17} />
            </button>
          </div>
        )}
      </div>
      <div
        className={clsx(
          'overflow-y-hidden px-[55px] text-center transition-all duration-500 lg:hidden',
          {
            'max-h-[400px]': open,
            'max-h-0': !open,
          },
        )}
      >
        {navigation.map((item, index) => (
          <button
            className={clsx(
              `${lilita.className} w-full border-t border-t-[rgba(106,138,170,0.50)] py-4 text-[18px] uppercase`,
              {
                'mt-[34px]': index === 0,
                'text-vivid-orange hover:text-vivid-orange':
                  active === item.refname,
                'text-pale-blue-gray hover:text-ice-blue':
                  active !== item.refname,
              },
            )}
            key={item.name}
            type="button"
            onClick={() => {
              if (refs) {
                const ref: HTMLDivElement | null | undefined =
                  refs?.[item.refname as keyof typeof refs];
                if (ref) {
                  ref.scrollIntoView({
                    behavior: 'smooth',
                  });
                }
              }
              setOpen(false);
            }}
          >
            {item.name}
          </button>
        ))}
        <a
          target="_blank"
          href="/pdf/gamster-whitepaper.pdf"
          className={`${lilita.className} mb-[34px] block w-full border-b border-t border-[rgba(106,138,170,0.50)] py-4 text-[18px] uppercase text-pale-blue-gray`}
        >
          Whitepaper
        </a>
      </div>
    </nav>
  );
}
