'use client';

import { ParallaxProvider } from 'react-scroll-parallax';
import { ReactNode } from 'react';
import WalletProvider from '@/app/contexts/Wallet';
import NavigationProvider from '@/app/contexts/Navigation';

export default function Provider({ children }: { children: ReactNode }) {
  return (
    <ParallaxProvider>
      <WalletProvider>
        <NavigationProvider>
          {children}
        </NavigationProvider>
      </WalletProvider>
    </ParallaxProvider>
  );
}
