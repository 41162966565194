import { StateSetter } from '@/app/types/StateSetter';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export type WalletsListType = {
  name: 'metamask' | 'coinbase' | 'trust wallet';
  icon: 'metamask' | 'coinbase' | 'trust';
  url: string;
};

type WalletObject = {
  address: string;
  wallet: WalletsListType;
  usdt: number;
  usdc: number;
  eth: number;
};

type WalletContextType = {
  wallet?: WalletObject | null;
  setWallet?: StateSetter<WalletObject | null>;
  setOpenWalletConnect?: StateSetter<boolean>;
  openWalletConnect?: boolean;
};

export const WalletsList: WalletsListType[] = [
  { name: 'metamask', icon: 'metamask', url: 'https://metamask.io/' },
  { name: 'coinbase', icon: 'coinbase', url: 'https://www.coinbase.com/' },
  { name: 'trust wallet', icon: 'trust', url: 'https://trustwallet.com/' }
] as const;

export const WalletContext = createContext<WalletContextType>({});

export default function WalletProvider({ children }: { children: ReactNode }) {
  const [wallet, setWallet] = useState<WalletObject | null>(null);
  const [openWalletConnect, setOpenWalletConnect] = useState(false);

  const value = useMemo(
    () => ({ wallet, setWallet, openWalletConnect, setOpenWalletConnect }),
    [wallet, setWallet, openWalletConnect, setOpenWalletConnect]
  );

  return (
    <WalletContext.Provider value={value}>
      {children}
    </WalletContext.Provider>
  );
}

export function useWallet() {
  const context = useContext(WalletContext);

  if (!context) {
    throw new Error('useWallet must be used within WalletProvider');
  }

  return context;
}
